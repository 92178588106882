const financialManageRoute = [
  {
    path: '/financialManage/agentSettlementManage',
    name: 'agentSettlementManage',
    component: () => import('@/views/agentPage/financialManage/settlementManage.vue'),
    meta: {
      parentPath: '/financialManage/agentSettlementManage',
      requireAuth: true,
      keepAlive: true,
      title: '结算管理'
    }
  },
  {
    path: '/financialManage/agentBillingManage',
    name: 'agentBillingManage',
    component: () => import('@/views/agentPage/financialManage/billingManage.vue'),
    meta: {
      parentPath: '/financialManage/agentBillingManage',
      requireAuth: true,
      keepAlive: true,
      title: '开票管理'
    }
  },
  {
    path: '/financialManage/agentInvoiceDetails',
    name: 'agentInvoiceDetails',
    component: () => import('@/views/agentPage/financialManage/children/invoiceDetails.vue'),
    meta: {
      parentPath: '/financialManage/agentBillingManage',
      requireAuth: true,
      title: '发票详情'
    }
  },
  {
    path: '/financialManage/agentMakeOutInvoice',
    name: 'agentMakeOutInvoice',
    meta: {
      title: '开票处理',
      parentPath: '/financialManage/agentBillingManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/financialManage/children/makeOutInvoice.vue')
  },
  {
    path: '/financialManage/agentBillingDetails',
    name: 'agentBillingDetails',
    meta: {
      title: '结算详情',
      parentPath: '/financialManage/agentSettlementManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/financialManage/children/settlementDetails.vue')
  },
  {
    path: '/financialManage/recordedSettlementPrice',
    name: 'recordedSettlementPrice',
    meta: {
      title: '结算录价',
      parentPath: '/financialManage/agentSettlementManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/financialManage/children/settlementDetails.vue')
  }
]
export default financialManageRoute
