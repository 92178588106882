<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getSelectAllDict } from '@/http/agentApi/common'
import Vue from 'vue'
export default {
  async mounted() {
    window.addEventListener('offline', () => {
      this.$message.error('网络已断开！')
    })
    window.addEventListener('online', () => {
      this.$message.success('网络已连接！')
    })
    // 获取字典所有数据呀
    const res = await getSelectAllDict
    const dictionaryEntry = JSON.parse(localStorage.getItem('dictionaryEntry'))
    this.$store.commit('getDictionaryEntry', res.data || dictionaryEntry)
    if (res.data['documentLeadingEdge'] || dictionaryEntry['documentLeadingEdge'])Vue.prototype.$fileUrl = this.$store.getters.getDictionaryItem('documentLeadingEdge')[0].dictName || dictionaryEntry['documentLeadingEdge'][0].dictName
  }
}
</script>
<style lang="scss">
#app,html,body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.tox-notifications-container {
  display: none !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
