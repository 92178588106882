import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    token: null,
    systemPlatformRoles: '03', // 03是经销商  04是代采商
    customColors: localStorage.getItem('customColors') || '#FFF', // 自定义颜色
    multiPageLabelArr: [], // 多页标签
    dictionaryEntry: null, // 字典所有数据
    allPermissionList: [] // 所有的权限列表
  },
  mutations: {
    // 获取用户所有的权限列表
    getAllPermissionList(state, arr) {
      state.allPermissionList = [...arr]
    },
    changeLoading(state, type) {
      state.loading = type
    },
    // 改变平台的角色
    changeSystemPlatformRoles(state, systemPlatformRoles) {
      state.systemPlatformRoles = systemPlatformRoles
      sessionStorage.setItem('systemPlatformRoles', systemPlatformRoles)
    },
    // 获取所有字典项
    getDictionaryEntry(state, dictionaryEntry) {
      state.dictionaryEntry = dictionaryEntry
      localStorage.setItem('dictionaryEntry', JSON.stringify(dictionaryEntry))
    },
    getToken(state, token) {
      state.token = token
      state.systemPlatformRoles === '03' ? sessionStorage.setItem('tokenCust', token) : sessionStorage.setItem('tokenAgent', token)
    },
    // 设置多页标签  添加的
    getmultiPageLabel(state, pageLabel) {
      const flag = state.multiPageLabelArr.some(item => {
        if (item.path === pageLabel.path)item.fullPath = pageLabel.fullPath
        return item.path === pageLabel.path
      })
      if (!flag)state.multiPageLabelArr.push(pageLabel)
    },
    // 设置多页标签  赋值的
    setmultiPageLabel(state, arr) {
      state.multiPageLabelArr = [...arr]
    },
    // 更改主题颜色
    setCustomColors(state, customColors) {
      state.customColors = customColors
      localStorage.setItem('customColors', customColors)
    }
  },
  actions: {},
  getters: {
    // 获取字典单个项的数据
    getDictionaryItem: (state) => (item) => {
      const dictionaryEntry = state.dictionaryEntry || JSON.parse(localStorage.getItem('dictionaryEntry'))
      return dictionaryEntry[item]
    }
  },
  modules: {}
})
