const procurementContractRoute = [
  {
    path: '/procurementContract/index',
    name: 'procurementContract',
    meta: {
      title: '采购合同管理',
      parentPath: '/procurementContract/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/procurementContract/index.vue')
  },
  {
    path: '/procurementContract/addProcurementContract',
    name: 'addProcurementContract',
    meta: {
      title: '新增采购合同',
      parentPath: '/procurementContract/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/procurementContract/children/addProcurementContract.vue')
  },
  {
    path: '/procurementContract/procurementContractDetails',
    name: 'procurementContractDetails',
    meta: {
      title: '采购合同详情',
      parentPath: '/procurementContract/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/procurementContract/children/procurementContractDetails.vue')
  }
]
export default procurementContractRoute
