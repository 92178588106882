const salesContractManagementRoute = [
  {
    path: '/salesContractManagement/index',
    name: 'salesContractManagement',
    meta: {
      title: '销售合同管理',
      parentPath: '/salesContractManagement/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/index.vue')
  },
  {
    path: '/salesContractManagement/index?contractStatus=2',
    name: 'salesContractManagementOne',
    meta: {
      title: '待代采商签署',
      keepAlive: true,
      parentPath: '/salesContractManagement/signed',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/index.vue')
  },
  {
    path: '/salesContractManagement/index?contractStatus=3',
    name: 'salesContractManagementTwo',
    meta: {
      title: '待关联合同',
      keepAlive: true,
      parentPath: '/salesContractManagement/associatedContract',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/index.vue')
  },
  {
    path: '/salesContractManagement/index?contractStatus=5',
    name: 'salesContractManagementThree',
    meta: {
      title: '待入库',
      keepAlive: true,
      parentPath: '/salesContractManagement/stockPending',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/index.vue')
  },
  {
    path: '/salesContractManagement/addSalesContract',
    name: 'addSalesContract',
    meta: {
      title: '录入结算价格',
      parentPath: '/financialManage/agentSettlementManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/children/salesContractDetails.vue')
  },
  {
    path: '/salesContractManagement/salesContractDetails',
    name: 'salesContractDetails',
    meta: {
      title: '销售合同详情',
      parentPath: '/salesContractManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/children/salesContractDetails.vue')
  },
  {
    path: '/salesContractManagement/additionalSettlement',
    name: 'additionalSettlement',
    meta: {
      title: '新增结算',
      parentPath: '/financialManage/agentSettlementManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/salesContractManagement/children/additionalSettlement.vue')
  }
]
export default salesContractManagementRoute
