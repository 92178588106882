const quotaManageRoute = [
  {
    path: '/quotaManage/index',
    name: 'quotaManageIndex',
    component: () => import('@/views/custPage/quotaManage/index.vue'),
    meta: {
      title: '额度管理',
      parentPath: '/quotaManage/index',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/quotaManage/creditApplication',
    name: 'creditApplication',
    component: () => import('@/views/custPage/quotaManage/children/creditApplication.vue'),
    meta: {
      title: '授信申请',
      parentPath: '/quotaManage/index',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/quotaManage/quotaInfo',
    name: 'quotaInfo',
    component: () => import('@/views/custPage/quotaManage/children/quotaInfo.vue'),
    meta: {
      title: '授信申请详情',
      parentPath: '/quotaManage/index',
      requireAuth: true
    }
  }
]
export default quotaManageRoute
