const custFinancialManageRoute = [
  {
    path: '/financialManage/custSettlementManage',
    name: 'custSettlementManage',
    component: () => import('@/views/custPage/financialManage/settlementManage.vue'),
    meta: {
      title: '结算管理',
      parentPath: '/financialManage/custSettlementManage',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/financialManage/custBillingManage',
    name: 'custBillingManage',
    component: () => import('@/views/custPage/financialManage/billingManage.vue'),
    meta: {
      title: '开票管理',
      parentPath: '/financialManage/custBillingManage',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/financialManage/custMakeOutInvoice',
    name: 'custMakeOutInvoice',
    component: () => import('@/views/custPage/financialManage/children/makeOutInvoice.vue'),
    meta: {
      title: '开票申请',
      parentPath: '/financialManage/index',
      requireAuth: true
    }
  },
  {
    path: '/financialManage/custBillingDetails',
    name: 'custBillingDetails',
    meta: {
      title: '结算详情',
      parentPath: '/financialManage/custSettlementManage',
      requireAuth: true
    },
    component: () =>
      import('@/views/custPage/financialManage/children/billingDetails.vue')
  },
  {
    path: '/financialManage/custInvoiceDetails',
    name: 'custInvoiceDetails',
    component: () => import('@/views/custPage/financialManage/children/invoiceDetails.vue'),
    meta: {
      parentPath: '/financialManage/custSettlementManage',
      requireAuth: true,
      title: '发票详情'
    }
  }
]
export default custFinancialManageRoute
