import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { Message } from 'element-ui'
import enterpriseInformationRoute from './agentRouter/enterpriseInformation'
import crmManagementRoute from './agentRouter/crmManagement'
import procurementContractRoute from './agentRouter/procurementContract'
import pickUpGoodsRoute from './agentRouter/pickUpGoods'
import productManagementRoute from './agentRouter/productManagement'
import financialManageRoute from './agentRouter/financialManage'
import cooperativeOfficeOARoute from './agentRouter/cooperativeOfficeOA'
import salesContractManagementRoute from './agentRouter/salesContractManagement'
import purchasePlanManageRoute from './custRouter/purchasePlanManage'
import purchaseContractManageRoute from './custRouter/purchaseContractManage'
import takeDeliveryManageRoute from './custRouter/takeDeliveryManage'
import custFinancialManageRoute from './custRouter/financialManage'
import quotaManageRoute from './custRouter/quotaManage'
// 需要登录的加上这个参数
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('@/views/agentPage/index.vue'),
    redirect: '/homePage/index',
    meta: {
      title: '首页',
      parentPath: '/enterpriseInformation/addInformation',
      requireAuth: true
    },
    children: [
      ...pickUpGoodsRoute,
      ...purchasePlanManageRoute,
      ...purchaseContractManageRoute,
      ...takeDeliveryManageRoute,
      ...custFinancialManageRoute,
      ...quotaManageRoute,
      ...financialManageRoute,
      ...cooperativeOfficeOARoute,
      ...salesContractManagementRoute,
      ...productManagementRoute,
      ...procurementContractRoute,
      ...enterpriseInformationRoute,
      ...crmManagementRoute,
      {
        path: '/homePage/index',
        name: 'homePageIndex',
        component: () => import('@/views/common/index.vue'),
        meta: {
          name: '首页',
          parentPath: '/homePage/index',
          requireAuth: true
        }
      },
      {
        path: '/customerMessage',
        name: 'customerMessage',
        meta: {
          title: '站内消息'
        },
        component: () => import('@/views/common/login/customerMessage.vue')
      },
      {
        path: '/invoiceManagement/lookedUpManagement',
        name: 'lookedUpManagement',
        meta: {
          title: '抬头管理',
          parentPath: '/invoiceManagement/lookedUpManagement',
          keepAlive: true,
          requireAuth: true
        },
        component: () =>
          import('@/views/common/invoiceManagement/lookedUpManagement.vue')
      },
      {
        path: '/institutionalFramework/organizationalManagement',
        name: 'organizationalManagement',
        meta: {
          title: '组织管理',
          parentPath: '/institutionalFramework/organizationalManagement',
          requireAuth: true
        },
        component: () =>
          import('@/views/common/institutionalFramework/organizationalManagement.vue')
      },
      {
        path: '/institutionalFramework/permission',
        name: 'permission',
        meta: {
          title: '权限管理',
          parentPath: '/institutionalFramework/permission',
          requireAuth: true
        },
        component: () =>
          import('@/views/common/institutionalFramework/permission.vue')
      },
      {
        path: '/institutionalFramework/role',
        name: 'role',
        meta: {
          title: '角色管理',
          parentPath: '/institutionalFramework/role',
          requireAuth: true
        },
        component: () => import('@/views/common/institutionalFramework/role.vue')
      },
      {
        path: '/institutionalFramework/user',
        name: 'user',
        meta: {
          title: '用户管理',
          parentPath: '/institutionalFramework/user',
          keepAlive: true,
          requireAuth: true
        },
        component: () => import('@/views/common/institutionalFramework/user.vue')
      },
      {
        path: '/personalCertificate',
        name: 'personalCertificate',
        meta: {
          title: '个人中心'
        },
        component: () => import('@/views/common/login/personalCertificate.vue')
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/common/login/register.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/common/login/index.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      title: '平台协议'
    },
    component: () => import('@/views/common/login/agreement.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/agentPage/wrongIndication/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    const systemPlatformRoles = sessionStorage.getItem('systemPlatformRoles') || '03'
    // 判断该路由是否需要登录权限
    if (store.state.token || sessionStorage.getItem(`${systemPlatformRoles === '03' ? 'tokenCust' : 'tokenAgent'}`)) {
      // 通过vuex state获取当前的token是否存在
      // 这里在判断一下  在登录的情况下  如果信息没有补入的话  就一直跳到信息补入的页面
      const userInfo = JSON.parse(localStorage.getItem(`${systemPlatformRoles === '03' ? 'userInfoCust' : 'userInfoAgent'}`))
      if (to.path !== '/enterpriseInformation/addInformation' && from.path !== '/login' && userInfo && userInfo.cmpState !== '99') {
        Message({
          message: userInfo.cmpState === '01' ? '请补入信息' : '用户信息审核中~',
          type: 'error'
        })
        next({
          path: '/enterpriseInformation/addInformation'
        })
        return
      }
      next()
    } else {
      Message({
        message: '非法查看，请先登录',
        type: 'error'
      })
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
