const cooperativeOfficeOARoute = [
  {
    path: '/cooperativeOfficeOA/paymentApprover',
    name: 'paymentApprover',
    component: () => import('@/views/agentPage/cooperativeOfficeOA/paymentApprover.vue'),
    meta: {
      requireAuth: true,
      parentPath: '/cooperativeOfficeOA/paymentApprover',
      keepAlive: true,
      title: '付款审批'
    }
  },
  {
    path: '/cooperativeOfficeOA/sealApprover',
    name: 'sealApprover',
    component: () => import('@/views/agentPage/cooperativeOfficeOA/paymentApprover.vue'),
    meta: {
      requireAuth: true,
      parentPath: '/cooperativeOfficeOA/sealApprover',
      keepAlive: true,
      title: '用印审批'
    }
  },
  {
    path: '/cooperativeOfficeOA/billLadingApproval',
    name: 'billLadingApproval',
    component: () => import('@/views/agentPage/cooperativeOfficeOA/paymentApprover.vue'),
    meta: {
      requireAuth: true,
      parentPath: '/cooperativeOfficeOA/billLadingApproval',
      keepAlive: true,
      title: '提单审批'
    }
  },
  {
    path: '/cooperativeOfficeOA/approvalDetails',
    name: 'approvalDetails',
    component: () => import('@/views/agentPage/cooperativeOfficeOA/children/approvalDetails.vue'),
    meta: {
      requireAuth: true,
      parentPath: '/cooperativeOfficeOA/sealApprover',
      title: '审批详情'
    }
  }
]
export default cooperativeOfficeOARoute
