const productManagementRoute = [
  {
    path: '/productManagement/index',
    name: 'productManagement',
    meta: {
      title: '产品管理',
      parentPath: '/productManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/productManagement/index.vue')
  },
  {
    path: '/productManagement/addProduct',
    name: 'addProduct',
    meta: {
      title: '新增产品',
      parentPath: '/productManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/productManagement/children/addProduct.vue')
  },
  {
    path: '/productManagement/productDetails',
    name: 'productDetails',
    meta: {
      title: '产品详情',
      parentPath: '/productManagement/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/productManagement/children/productDetails.vue')
  }
]
export default productManagementRoute
