const crmManagementRoute = [
  {
    path: '/crmManagement/cooperativeEnterprise',
    name: 'cooperativeEnterprise',
    meta: {
      title: '合作企业管理',
      parentPath: '/crmManagement/cooperativeEnterprise',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/cooperativeEnterprise.vue')
  },
  {
    path: '/crmManagement/addCooperativeEnterprise',
    name: 'addCooperativeEnterprise',
    meta: {
      title: '新增合作企业',
      parentPath: '/crmManagement/cooperativeEnterprise',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/addCooperativeEnterprise.vue')
  }, {
    path: '/crmManagement/editCooperativeEnterprise',
    name: 'editCooperativeEnterprise',
    meta: {
      title: '编辑合作企业',
      parentPath: '/crmManagement/cooperativeEnterprise',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/editCooperativeEnterprise.vue')
  }, {
    path: '/crmManagement/cooperativeEnterpriseDeails',
    name: 'cooperativeEnterpriseDeails',
    meta: {
      title: '合作企业详情',
      parentPath: '/crmManagement/cooperativeEnterprise',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/cooperativeEnterpriseDeails.vue')
  },
  {
    path: '/crmManagement/creditManagement',
    name: 'creditManagement',
    meta: {
      title: '授信管理',
      parentPath: '/crmManagement/creditManagement',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/creditManagement.vue')
  }, {
    path: '/crmManagement/addCredit',
    name: 'addCustomer',
    meta: {
      title: '新增授信',
      parentPath: '/crmManagement/creditManagement',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/addCredit.vue')
  }, {
    path: '/crmManagement/creditDetails',
    name: 'creditDetails',
    meta: {
      title: '授信管理详情',
      parentPath: '/crmManagement/creditManagement',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/creditDetails.vue')
  }, {
    path: '/crmManagement/creditAudit',
    name: 'creditAudit',
    meta: {
      title: '授信管理审核',
      parentPath: '/crmManagement/creditManagement',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/creditDetails.vue')
  }, {
    path: '/crmManagement/creditAdjust',
    name: 'creditAdjust',
    meta: {
      title: '额度调整',
      parentPath: '/crmManagement/creditManagement'
    },
    component: () =>
      import('@/views/agentPage/crmManagement/children/creditDetails.vue')
  }
]
export default crmManagementRoute
