// 系统的配置项在这里呀
let BSERURL = ''
let IMGPATH = 'http://120.77.45.206/images/'
switch (true) {
  case location.href.indexOf('47.104.208.84') >= 0: // 生产环境
    BSERURL = `https://api.369tecgroup.com/plat/approveabc/`
    IMGPATH = `https://image.369tecgroup.com/images/`
    break
  case location.href.indexOf('369tecgroup') >= 0: // 生产环境
    BSERURL = `https://api.369tecgroup.com/plat/approveabc/`
    IMGPATH = `https://image.369tecgroup.com/images/`
    break
  case location.href.indexOf('120.77.45.206') >= 0: // 测试环境
    BSERURL = `http://120.77.45.206:8086/plat/approveabc/`
    break
  case location.href.indexOf('120.25.107.214') >= 0: // 旧的测试环境 改为开发环境  兼容测试
    BSERURL = `http://120.25.107.214:8086/plat/approveabc/`
    break
  case location.pathname.indexOf('-pre') >= 0: // pre测试环境
    BSERURL = `http://112.74.164.238:8086/plat/approveabc/`
    break
  default: // 开发环境
    BSERURL = `http://120.25.107.214:8086/plat/approveabc/`
    IMGPATH = `http://120.77.45.206/images/`
    break
}
// 录入方
const intoParty = [{ id: '00', name: '企业方' }, { id: '01', name: '平台方' }]
// 数据来源
const dataSources = [{ id: '00', name: '平台录入' }, { id: '01', name: '用户录入' }, { id: '02', name: '银行同步' }]
// 角色类型
const roleType = [
  { id: '222', name: '业务类' },
  { id: '221', name: '管理类' }
]
// 公共角色平台
const rolePlatform = [
  { id: '00', name: '默认自己所属企业' },
  { id: '0101', name: '核心企业' },
  { id: '0102', name: '融资企业' },
  { id: '02', name: '资金方' }
]
// 权限类型
const qType = [
  { id: 1, name: '栏目' },
  { id: 2, name: '菜单' },
  { id: 3, name: '按钮' }
]
// 机构
const orgCodeArr = [
  { id: '00000', name: '产融链平台' },
  { id: '0000', name: '平台方' },
  { id: '10024', name: '资金方' },
  { id: '01', name: '核心企业' },
  { id: '02', name: '融资企业' },
  { id: '03', name: '核心企业&融资企业' }
]
// 新闻类型
const newsType = [
  { id: 1, name: '公司新闻' },
  { id: 2, name: '行业动态' }
]
// 操作类型
const operationType = [
  { id: 0, name: '登录' },
  { id: 1, name: '新增' },
  { id: 2, name: '修改' },
  { id: 3, name: '删除' }
]
// 业务类型
const businessType = [
  { id: 1, name: '登录模块' },
  { id: 2, name: '产品模块' },
  { id: 3, name: '额度模块' },
  { id: 4, name: '融资模块' }
]
// 企业角色
const enterpriseRole = [
  { id: '01', name: '核心企业' },
  { id: '02', name: '供应商' },
  { id: '03', name: '经销商' }
]
// 账户状态
const accountStatus = [
  { id: '01', name: '待补录' },
  { id: '02', name: '待平台审核' },
  { id: '03', name: '驳回' },
  { id: '10', name: '通过' }
]
// 注册来源
const registeredSource = [
  { id: '00', name: '平台代理' },
  { id: '01', name: '门户商城' }
]
export { IMGPATH, dataSources, qType, rolePlatform, orgCodeArr, newsType, operationType, businessType, accountStatus, registeredSource, BSERURL, enterpriseRole, intoParty, roleType }
