const takeDeliveryManageRoute = [
  {
    path: '/takeDeliveryManage/index',
    name: 'takeDeliveryManageIndex',
    component: () => import('@/views/custPage/takeDeliveryManage/index.vue'),
    meta: {
      title: '提货管理',
      parentPath: '/takeDeliveryManage/index',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/takeDeliveryManage/takeDeliveryInfo',
    name: 'takeDeliveryInfo',
    component: () => import('@/views/custPage/takeDeliveryManage/children/takeDeliveryInfo.vue'),
    meta: {
      title: '提货详情',
      parentPath: '/takeDeliveryManage/index',
      requireAuth: true
    }
  }
]
export default takeDeliveryManageRoute
