const purchaseContractManageRoute = [
  {
    path: '/purchaseContractManage/index',
    name: 'purchaseContractManageIndex',
    component: () => import('@/views/custPage/purchaseContractManage/index.vue'),
    meta: {
      title: '采购合同管理',
      parentPath: '/purchaseContractManage/index',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/purchaseContractManage/contractInfo',
    name: 'contractInfo',
    component: () => import('@/views/custPage/purchaseContractManage/children/contractInfo.vue'),
    meta: {
      title: '采购合同详情',
      parentPath: '/purchaseContractManage/index',
      requireAuth: true
    }
  },
  {
    path: '/purchaseContractManage/takeDeliveryApply',
    name: 'takeDeliveryApply',
    component: () => import('@/views/custPage/purchaseContractManage/children/takeDeliveryApply.vue'),
    meta: {
      title: '提货申请',
      parentPath: '/purchaseContractManage/index',
      requireAuth: true
    }
  }
]
export default purchaseContractManageRoute
