// 引入axios
import axios from 'axios'
// 引入配置项
import { BSERURL } from '@/config/index'
// 引入路由
import router from '@/router/index'
// 引入vuex
import store from '@/store/index'
// 导入弹出提示组件
import {
  Message
} from 'element-ui'
// 创建axios请求并在里面加上参数设置
const $http = axios.create({
  baseURL: BSERURL, // 配置baseURL
  timeout: 15000, // 设置超时时间
  headers: {
    'Content-Type': 'application/json'// 设置数据类型
  }
})
// 处理请求前的数据
$http.interceptors.request.use(
  config => {
    if (store.state.token || sessionStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'tokenCust' : 'tokenAgent'}`)) {
      config.headers['token'] = store.state.token || sessionStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'tokenCust' : 'tokenAgent'}`)
    }
    if (config.method === 'post' && (config.data.constructor === String || config.data.constructor === Object)) {
      const obj = JSON.parse(JSON.stringify(config.data))
      if (!obj.operFlat)obj.operFlat = sessionStorage.getItem('systemPlatformRoles') === '03' ? 1 : 2
      config.data = { ...obj }
    }
    store.commit('changeLoading', true)
    return config
  }
)
// 处理请求后的数据
$http.interceptors.response.use(res => {
  store.commit('changeLoading', false)
  switch (res.data.code) {
    case 1000 :
      sessionStorage.setItem('tokenAgent', null)
      Message({
        message: res.data.msg,
        type: 'error'
      })
      router.push('/login')
      break
    case '1000':
      sessionStorage.setItem('tokenAgent', null)
      Message({
        message: res.data.msg,
        type: 'error'
      })
      router.push('/login')
      break
    default:
      break
  }
  return res.data
}, err => {
  if (err.message.includes('timeout')) {
    Message({
      message: '请求超时，请稍后重试',
      type: 'error'
    })
  }
  if (err.code === 'ECONNABORTED') {
    Message({
      message: '远程主机拒绝网络连接',
      type: 'error'
    })
  }
  if (err.message === 'Network Error') {
    Message({
      message: '网络错误',
      type: 'error'
    })
  }
  store.commit('changeLoading', false)
  return Promise.reject(err)
})
// get数据请求方法
export const getRequest = (url, params, successCallback, failureCallback) => {
  $http.get(url, {
    params
  }).then(res => {
    if (res.code === 200 || res.captchaBase64) {
      successCallback(res)
      return
    }
    Message({
      message: res.message || res.msg,
      type: 'error'
    })
    if (failureCallback) failureCallback(res)
  }, () => {
    if (failureCallback) failureCallback()
  })
}
// POST请求数据方法
export const postRequest = (url, data, successCallback, failureCallback) => {
  $http.post(url, data).then(res => {
    if (res.code === 200) {
      successCallback(res)
      return
    }
    Message({
      message: res.message || res.msg,
      type: 'error'
    })
    if (failureCallback) failureCallback(res)
  }, () => {
    if (failureCallback) failureCallback()
  })
}
// 删除请求数据方法
export const deleteRequest = (url, data, successCallback, failureCallback) => {
  $http.delete(url, data).then(res => {
    if (res.code === 200) {
      successCallback(res)
      return
    }
    Message({
      message: res.message || res.msg,
      type: 'error'
    })
    if (failureCallback) failureCallback(res)
  }, () => {
    if (failureCallback) failureCallback()
  })
}
