import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import sanliujiu from 'sanliujiu'
import '@/assets/css/normalize.min.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/custom.scss'
import { BSERURL, IMGPATH } from './config/index'
import { numberToCurrency } from '@/unit/index'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(sanliujiu)
try {
  Vue.prototype.$fileUrl = JSON.parse(localStorage.getItem('dictionaryEntry'))['documentLeadingEdge'][0].dictName
} catch (err) {
  console.log('HT369-V1.0-202212221' + err)
}
Vue.prototype.$localPicture = IMGPATH // 本地写代码图片地址
Vue.prototype.$baseUpload = `${BSERURL}/file/uploadfile`
Vue.prototype.$errImage = require('assets/image/default_graph.png') // 没有图片时候的显示
Vue.prototype.$getUserInfoParameter = function(parameter) {
  const userInfo = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`))
  return userInfo[parameter]
}
Vue.filter('numberToCurrency', numberToCurrency)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
