const purchasePlanManageRoute = [
  {
    path: '/purchasePlanManage/index',
    name: 'purchasePlanManageIndex',
    component: () => import('@/views/custPage/purchasePlanManage/index.vue'),
    meta: {
      title: '采购计划管理',
      parentPath: '/purchasePlanManage/index',
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/purchasePlanManage/purchasePlanAdd',
    name: 'purchasePlanAdd',
    component: () => import('@/views/custPage/purchasePlanManage/children/purchasePlanAdd.vue'),
    meta: {
      title: '新增采购计划',
      keepAlive: true,
      parentPath: '/purchasePlanManage/index',
      requireAuth: true
    }
  },
  {
    path: '/purchasePlanManage/purchasePlanInfo',
    name: 'purchasePlanInfo',
    component: () => import('@/views/custPage/purchasePlanManage/children/purchasePlanInfo.vue'),
    meta: {
      title: '采购计划详情',
      parentPath: '/purchasePlanManage/index',
      requireAuth: true
    }
  }
]
export default purchasePlanManageRoute
