const pickUpGoodsRoute = [
  {
    path: '/pickUpGoods/index',
    name: 'pickUpGoods',
    meta: {
      title: '提货管理',
      parentPath: '/pickUpGoods/index',
      keepAlive: true,
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/pickUpGoods/index.vue')
  },
  {
    path: '/pickUpGoods/pickUpGoodsDetails',
    name: 'pickUpGoodsDetails',
    meta: {
      title: '提货详情',
      parentPath: '/pickUpGoods/index',
      requireAuth: true
    },
    component: () =>
      import('@/views/agentPage/pickUpGoods/children/pickUpGoodsDetails.vue')
  }
]
export default pickUpGoodsRoute
